import { React } from '@biletiniz/ui-core';
import { Layout } from '@biletiniz/ui-layout';
import { ErrorCard } from '@biletiniz/ui-patterns-card';
import { Button } from '@biletiniz/ui-button';
import { useI18n } from '@biletiniz/intl';
import { useRouter } from '../src/hooks';

export default function Custom404() {
  const i18n = useI18n();
  const { router } = useRouter();
  const onReloadHandler = () => router.reload();
  const onGoHomeHandler = () => router.push('/');

  return (
    <Layout>
      <Layout.Header router={router} />
      <Layout.Container>
        <ErrorCard mt={4} mb={5}>
          <ErrorCard.Image />
          <ErrorCard.Title>Oops!</ErrorCard.Title>
          <ErrorCard.Description>
            {`${i18n.t('errors.canNotFindPage')}, ${i18n.t('tryAgain')}!`}
          </ErrorCard.Description>
          <ErrorCard.Actions flexDirection={['column', 'row']}>
            <ErrorCard.Action mb={[1, 0]}>
              <Button
                size="large"
                width="full"
                onClick={() => onReloadHandler()}
              >
                {i18n.t('tryAgain')}
              </Button>
            </ErrorCard.Action>
            <ErrorCard.Action>
              <Button
                size="large"
                width="full"
                onClick={() => onGoHomeHandler()}
              >
                {i18n.t('goToHome')}
              </Button>
            </ErrorCard.Action>
          </ErrorCard.Actions>
        </ErrorCard>
      </Layout.Container>
      <Layout.Footer />
    </Layout>
  );
}

export async function getStaticProps(context) {
  const { default: lngDict = {} } = await import(
    `@biletiniz/intl/locales/${context.locale}.json`
  );
  return {
    props: { lng: context.locale, lngDict },
  };
}
